// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-academy-opium-call-option-academycalloption-md": () => import("./../../../src/pages/academy/opium-call-option/academycalloption.md" /* webpackChunkName: "component---src-pages-academy-opium-call-option-academycalloption-md" */),
  "component---src-pages-academy-opium-insurance-academyinsurance-md": () => import("./../../../src/pages/academy/opium-insurance/academyinsurance.md" /* webpackChunkName: "component---src-pages-academy-opium-insurance-academyinsurance-md" */),
  "component---src-pages-academy-opium-staking-academystaking-md": () => import("./../../../src/pages/academy/opium-staking/academystaking.md" /* webpackChunkName: "component---src-pages-academy-opium-staking-academystaking-md" */),
  "component---src-pages-academy-opium-turbo-academyturbo-md": () => import("./../../../src/pages/academy/opium-turbo/academyturbo.md" /* webpackChunkName: "component---src-pages-academy-opium-turbo-academyturbo-md" */),
  "component---src-pages-blog-bridge-protection-blog-bridge-md": () => import("./../../../src/pages/blog/bridge-protection/blog-bridge.md" /* webpackChunkName: "component---src-pages-blog-bridge-protection-blog-bridge-md" */),
  "component---src-pages-blog-eth-dump-protection-eth-dump-protection-md": () => import("./../../../src/pages/blog/eth-dump-protection/ETHDumpProtection.md" /* webpackChunkName: "component---src-pages-blog-eth-dump-protection-eth-dump-protection-md" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-looking-back-august-looking-back-and-forward-august-md": () => import("./../../../src/pages/blog/looking-back-august/LookingBackAndForwardAugust.md" /* webpackChunkName: "component---src-pages-blog-looking-back-august-looking-back-and-forward-august-md" */),
  "component---src-pages-blog-looking-back-july-looking-back-and-forward-july-md": () => import("./../../../src/pages/blog/looking-back-july/LookingBackAndForwardJuly.md" /* webpackChunkName: "component---src-pages-blog-looking-back-july-looking-back-and-forward-july-md" */),
  "component---src-pages-blog-looking-back-june-looking-back-and-forward-june-md": () => import("./../../../src/pages/blog/looking-back-june/looking-back-and-forward-june.md" /* webpackChunkName: "component---src-pages-blog-looking-back-june-looking-back-and-forward-june-md" */),
  "component---src-pages-blog-looking-back-may-looking-back-and-forward-may-md": () => import("./../../../src/pages/blog/looking-back-may/looking-back-and-forward-may.md" /* webpackChunkName: "component---src-pages-blog-looking-back-may-looking-back-and-forward-may-md" */),
  "component---src-pages-blog-opium-bsc-blog-opiumon-bsc-md": () => import("./../../../src/pages/blog/opium-bsc-blog/OpiumonBSC.md" /* webpackChunkName: "component---src-pages-blog-opium-bsc-blog-opiumon-bsc-md" */),
  "component---src-pages-blog-opium-call-option-opium-call-option-md": () => import("./../../../src/pages/blog/opium-call-option/OpiumCallOption.md" /* webpackChunkName: "component---src-pages-blog-opium-call-option-opium-call-option-md" */),
  "component---src-pages-blog-realt-protection-real-t-md": () => import("./../../../src/pages/blog/realt-protection/RealT.md" /* webpackChunkName: "component---src-pages-blog-realt-protection-real-t-md" */),
  "component---src-pages-blog-spacex-protection-launch-space-x-protection-md": () => import("./../../../src/pages/blog/spacex-protection/LaunchSpaceXProtection.md" /* webpackChunkName: "component---src-pages-blog-spacex-protection-launch-space-x-protection-md" */),
  "component---src-pages-blog-uma-opium-um-aarticle-md": () => import("./../../../src/pages/blog/uma-opium/UMAarticle.md" /* webpackChunkName: "component---src-pages-blog-uma-opium-um-aarticle-md" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

